module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/lukegiraudeau/code/src/web/longrunmopeds/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Long Run Mopeds","short_name":"Long Run Mopeds","start_url":"/","background_color":"#ffffff","theme_color":"#6200ee","display":"minimal-ui","icon":"src/static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ad52c83e692d3c092f49e552697b09ee"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
