// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/about-template.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contact-template.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-homepage-template-tsx": () => import("./../../../src/templates/homepage-template.tsx" /* webpackChunkName: "component---src-templates-homepage-template-tsx" */),
  "component---src-templates-moped-template-tsx": () => import("./../../../src/templates/moped-template.tsx" /* webpackChunkName: "component---src-templates-moped-template-tsx" */),
  "component---src-templates-mopeds-template-tsx": () => import("./../../../src/templates/mopeds-template.tsx" /* webpackChunkName: "component---src-templates-mopeds-template-tsx" */)
}

