import { Link } from 'gatsby';
import React from 'react';
import * as Icons from 'react-feather';

import '../scss/footer.scss';

const Footer = () => {
    return (
        <div className="__footer-sticky">
            <div className="footer row">
                <Link to="/" className="home-link"><h1>Long Run Mopeds</h1></Link>

                <div className="row links">
                    <div className="link">
                        <Icons.Phone className="icon" />
                        <a href="tel:+1 (540)-330-5601">+1 (540)-330-5601</a>
                    </div>

                    <span className="dot">&middot;</span>

                    <div className="link">
                        <Icons.Mail className="icon" />
                        <a target="blank" href="mailto:longrunmopeds@yahoo.com">longrunmopeds@yahoo.com</a>
                    </div>

                    <span className="dot">&middot;</span>

                    <div className="link">
                        <Icons.Facebook className="icon" />
                        <a target="blank" href="https://www.facebook.com/LongRunMopeds/">facebook.com/LongRunMopeds</a>
                    </div>
                </div>
            </div>
            <div className="purple-stripe"></div>
        </div>
    )
}

export default Footer
