import React, { Component } from 'react';
import Link from './Link';
import { navigate, withPrefix } from 'gatsby';
import * as logo from '../images/logo.png';
import IconButton from './IconButton';
import * as Icons from 'react-feather';

import '../scss/app-bar.scss';
import { aboutPath, contactPath, mopedsPath } from '../gatsby/routes';

export const ButtonLink: React.FC<{
    onClick: () => void,
    to: string,
    activeClassName?: string,
    className: string
}> = ({ onClick, to, children, className, activeClassName }) => {
    let isCurrent = false;

    if (typeof window !== 'undefined' && window?.location?.pathname)
        isCurrent = window.location.pathname === withPrefix(to)

    return (
        <a style={{ cursor: 'pointer' }} className={`${className}${isCurrent && activeClassName ? ` ${activeClassName}` : ''}`} onClick={() => {
            navigate(to);
            onClick();
        }}>{children}</a>
    )
}

export const AppBarLogo: React.FC<{ closeLinks: () => void }> = ({ closeLinks }) => {
    return (
        <ButtonLink onClick={closeLinks} to="/" className="logo">
            <img alt='long run mopeds logo' src={logo} />
            <h2>
                Long Run Mopeds
            </h2>
        </ButtonLink>
    )
}

export default class AppBar extends Component<{ fadeOnScroll: boolean }> {
    state = {
        linksOpen: false,
        scrolled: false
    }

    componentDidMount() {
        window.onload = this.updateScrolled;
        window.addEventListener('scroll', this.updateScrolled);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateScrolled);
        window.onload = () => { }
    }

    updateScrolled = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        this.setState({
            scrolled: winScroll > 20,
        })
    }


    toggleLinks(force?: boolean) {
        const { linksOpen } = this.state;
        this.setState({ linksOpen: force == null || force == undefined ? linksOpen : force });
    }

    render() {

        const { linksOpen, scrolled } = this.state;
        const { fadeOnScroll } = this.props;

        return (
            <div>
                <div
                    className={`appbar${linksOpen ? ' links-open' : ''}${scrolled ? ' scrolled' : ''}${fadeOnScroll ? ' fade-on-scroll' : ''}`}>
                    <div className="appbar-limiter">
                        <AppBarLogo closeLinks={() => this.toggleLinks(false)} />
                        <div className="mobile-menu-button">
                            <IconButton aria-label="Menu" onClick={() => {
                                this.toggleLinks(true);
                            }} icon={(<Icons.Menu />)} />
                        </div>
                        <div onClick={() => this.toggleLinks(false)} className="links-overlay mobile-only" />
                        <div className="links">
                            <div className="links-header mobile-only">
                                <AppBarLogo closeLinks={() => this.toggleLinks(false)} />
                                <div className="x-button" onClick={() => {
                                    this.toggleLinks(false);
                                }}>
                                    <Icons.X />
                                </div>
                            </div>
                            <hr className="divider mobile-only" />
                            <div className="links-inline">
                                <ButtonLink onClick={() => this.toggleLinks(false)} className="link" to={mopedsPath} activeClassName="current">Mopeds</ButtonLink>
                                <ButtonLink onClick={() => this.toggleLinks(false)} className="link" to={aboutPath} activeClassName="current">About</ButtonLink>
                                <ButtonLink onClick={() => this.toggleLinks(false)} to={contactPath} className="purple-button">
                                    <span style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: '100%'
                                    }}>Contact Us</span>
                                </ButtonLink>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="appbar-push"></div>
            </div>
        )
    }
}
