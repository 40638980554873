import React, { useEffect } from 'react';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import '../scss/responsive.scss';
import '../scss/index.scss';
import { aboutPath } from '../gatsby/routes';
import firebase from '../firebase/firebase';

export interface LayoutProps {
    location: {
        pathname: string
    }
}

export const Layout: React.FC<LayoutProps> = ({ children, location }) => {
    let { pathname } = location;

    useEffect(() => {
        // firebase.firestore().doc('/mopeds/bintelli-flash').update({
        //     specs: {
        //         Engine: {
        //             ['Engine Type']: 'BN139QMB',
        //             ['Cooling System']: 'Air Cooled',
        //             ['Bore X Stroke']: '4 Stroke',
        //             ['Compression Ratio']: '10.4 : 1',
        //             ['Horse Power']: '2.4kw/ 8000r/ min',
        //             ['Max Torque']: '2.8N.m / 6500r/ min',
        //             ['Ignition Type']: 'CDI',
        //             ['Starter System']: 'Electric / Kick',
        //             ['Engine Oil']: 'SJ10W - 40',
        //             Transmission: 'CVT',
        //             ['Spark Plug']: 'CR7HSA',
        //             Belt: 'Gates 669MM'
        //         },
        //         Scooter: {
        //             ['Front Suspension']: 'Straight',
        //             ['Rear Suspension']: 'Single',
        //             ['Front Tire']: '3.50-10',
        //             ['Rear Tire']: '3.50-10',
        //             ['Brake (front/rear)']: 'Disc / Drum',
        //             Dimensions: '72.1″ x 27.5″ x 42.0″',
        //             ['Seat Height']: '29″',
        //             ['Fuel Capacity']: '1.40gallon',
        //             ['Load Capacity']: '300lbs',
        //             ['Gross Weight']: '210lbs',
        //             Battery: '12V/7AH',
        //             Lights: 'LED / Halogen'
        //         }
        //     }
        // }).then(_ => {
        //     console.log('done');
        // });
    }, []);

    return (
        <div>
            <div className='page'>
                <AppBar fadeOnScroll={
                    pathname === '/' ||
                    pathname === '' ||
                    pathname === aboutPath ||
                    pathname === aboutPath + '/'} />
                <div className="__content__">
                    {children}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Layout;